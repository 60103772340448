import * as React from "react";
import { Button } from "../ui/button";
import Image from "next/image";
import Link from "next/link";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { cn } from "@/lib/utils";
import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

const components: { title: string; href: string; description: string }[] = [
  {
    title: "TikTok virality",
    href: "https://crayo.ai/dashboard",
    description: "Use Crayo's AI tools to create viral TikTok content.",
  },
  {
    title: "Youtube Automation",
    href: "https://crayo.ai/dashboard",
    description: "Automate YouTube content creation to save time and boost productivity.",
  },
  {
    title: "Faceless content",
    href: "https://crayo.ai/dashboard",
    description: "Create engaging faceless videos with AI avatars and voiceovers.",
  },
  {
    title: "Instagram monetization",
    href: "https://crayo.ai/dashboard",
    description: "Boost Instagram earnings with eye-catching Reels and Stories.",
  },
  {
    title: "Subtitles",
    href: "https://crayo.ai/dashboard",
    description: "Add accurate subtitles quickly using AI-powered generation.",
  },
  // ... (other components)
];

// Add this new component for mobile use
const MobileFeature: React.FC<{
  href: string;
  imageSrc?: string;
  title: string;
  children?: React.ReactNode;
}> = ({ href, imageSrc, title, children }) => {
  return (
    <Link
      href={href}
      prefetch={false}
      onClick={
        href === "https://crayo.ai/blog"
          ? (e) => {
              e.preventDefault();
              window.open(href, "_blank");
            }
          : undefined
      }
      className="group block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="space-y-1">
          <div className="flex flex-row items-center gap-1 text-sm font-medium leading-none">
            {title}
            <Image
              src="https://crayo-lp.vercel.app/rightArrow.png"
              alt="arrow"
              width={10}
              height={10}
              className="-translate-x-4 transform opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
            />
          </div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
        </div>
        {imageSrc && <Image src={imageSrc} alt={title} width={52} height={52} />}
      </div>
    </Link>
  );
};

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className="flex w-full flex-row items-center justify-between px-6 py-6 md:px-6 md:py-4">
      <Link href="/" className="w-1/4 transition duration-200 hover:opacity-70">
        <Image
          src="https://crayo-lp.vercel.app/crayoBlue.png"
          alt="CRAYO Logo"
          width={120}
          height={40}
        />
      </Link>

      {isMobile ? (
        <Sheet open={menuOpen} onOpenChange={setMenuOpen}>
          <SheetTrigger asChild>
            <button className="p-0 transition-transform duration-200 hover:scale-110">
              <Image
                src="https://crayo-lp.vercel.app/hamburger.png"
                alt="Menu"
                width={21}
                height={21}
              />
            </button>
          </SheetTrigger>
          <SheetContent side="top" className={`w-full rounded-b-2xl bg-white px-8 py-8 font-sans`}>
            <Accordion type="single" collapsible className="font-ambit mt-10">
              <AccordionItem value="features">
                <AccordionTrigger>Features</AccordionTrigger>
                <AccordionContent>
                  <ul className="space-y-3">
                    <li className="row-span-3">
                      <p className="mb-2 border-b pb-1 pl-3 text-[10px]">VIDEO CONCEPTS</p>
                      <div>
                        <MobileFeature
                          href="https://crayo.ai/create/story-video?step=1"
                          imageSrc="https://crayo-lp.vercel.app/menuStoryVideo.png"
                          title="Story video"
                        >
                          Generate an AI-generated story video
                        </MobileFeature>
                        <MobileFeature
                          href="https://crayo.ai/tools/voiceovers?step=0"
                          imageSrc="https://crayo-lp.vercel.app/menuVoiceover.png"
                          title="AI voiceover"
                        >
                          Make high quality voiceovers in seconds
                        </MobileFeature>
                        <MobileFeature
                          href="https://crayo.ai/create/text-video?step=0"
                          imageSrc="https://crayo-lp.vercel.app/menuChatGPT.png"
                          title="Fake texts video"
                        >
                          Create fake text conversation videos for Instagram, TikTok, and Shorts
                        </MobileFeature>
                      </div>
                    </li>
                    <li className="relative">
                      <div className="absolute inset-0"></div>
                      <div className="relative z-10">
                        <p className="mb-2 border-b pb-1 pl-3 text-[10px]">EDITING TOOLS</p>

                        <MobileFeature
                          href="https://crayo.ai/tools/ideas"
                          title="AI-generated story script"
                        />
                        <MobileFeature href="https://crayo.ai/tools/images" title="AI images" />
                        <MobileFeature
                          href="https://crayo.ai/create/blur-video?step=1"
                          title="Blur video"
                        />
                        <MobileFeature
                          href="https://crayo.ai/tools/youtube-downloader"
                          title="Youtube downloader"
                        />
                        <MobileFeature
                          href="https://crayo.ai/tools/tiktok-downloader"
                          title="Tiktok downloader"
                        />
                      </div>
                    </li>
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="use-cases">
                <AccordionTrigger>Use Cases</AccordionTrigger>
                <AccordionContent>
                  <ul className="grid gap-3">
                    {components.map((component) => (
                      <MobileFeature
                        key={component.title}
                        title={component.title}
                        href={component.href}
                      >
                        {component.description}
                      </MobileFeature>
                    ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="resources">
                <AccordionTrigger>Resources</AccordionTrigger>
                <AccordionContent>
                  <ul className="grid gap-3">
                    <MobileFeature href="https://crayo.tolt.io/login" title="Affiliate program">
                      Earn 20% on all paid referrals to Crayo.
                    </MobileFeature>
                    <MobileFeature href="https://crayo.ai/blog" title="Blog">
                      Find news, insights, and best practices.
                    </MobileFeature>
                    <MobileFeature href="https://crayo.ai/discord" title="Community Discord">
                      Join for customer support and feedback.
                    </MobileFeature>
                    <MobileFeature href="https://crayo.ai/guide" title="Guide">
                      Learn how to maximize the use of Crayo.
                    </MobileFeature>
                  </ul>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Link
              href="https://crayo.ai/discord"
              className="align-center font-ambit block items-center justify-center border-b py-3 text-start text-[18px] tracking-tight"
            >
              Join Discord
            </Link>
            <Link
              href="/pricing"
              className="align-center font-ambit block items-center justify-center border-b py-3 text-start text-[18px] tracking-tight"
            >
              Pricing
            </Link>
            <div className="mt-16 flex flex-col space-y-4">
              <Button
                href="https://crayo.ai/dashboard"
                className="font-ambit w-full space-x-3 bg-crayoBlue hover:bg-crayoBlue/80"
              >
                <Image
                  src="https://crayo-lp.vercel.app/lightningWhite.png"
                  alt="Lightning"
                  width={14}
                  height={14}
                />
                <span className="font-ambit-bold text-white">Get started</span>
              </Button>
            </div>
          </SheetContent>
        </Sheet>
      ) : (
        <>
          <NavigationMenu className="h-full w-1/2">
            <NavigationMenuList className="space-x-5">
              <NavigationMenuItem>
                <NavigationMenuTrigger>Features</NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="relative grid md:w-[550px] lg:w-[650px] lg:grid-cols-[1.25fr_.75fr]">
                    <li className="row-span-3 border-r border-slate-200 p-4">
                      <p className="pb-1 text-[10px]">VIDEO CONCEPTS</p>
                      <div>
                        <Feature
                          href="https://crayo.ai/create/story-video?step=1"
                          imageSrc="https://crayo-lp.vercel.app/menuStoryVideo.png"
                          title="Story video"
                        >
                          Generate an AI-generated story video
                        </Feature>
                        <Feature
                          href="https://crayo.ai/tools/voiceovers?step=0"
                          imageSrc="https://crayo-lp.vercel.app/menuVoiceover.png"
                          title="AI voiceover"
                        >
                          Make high quality voiceovers in seconds
                        </Feature>
                        <Feature
                          href="https://crayo.ai/create/text-video?step=0"
                          imageSrc="https://crayo-lp.vercel.app/menuChatGPT.png"
                          title="Fake texts video"
                        >
                          Create fake text conversation videos for Instagram, TikTok, and Shorts
                        </Feature>
                      </div>
                    </li>
                    <li className="relative">
                      <div className="absolute inset-0"></div>
                      <div className="relative z-10 p-4">
                        <p className="pb-1 text-[10px]">EDITING TOOLS</p>

                        <Feature
                          href="https://crayo.ai/tools/ideas"
                          title="AI-generated story script"
                        />
                        <Feature href="https://crayo.ai/tools/images" title="AI images" />
                        <Feature
                          href="https://crayo.ai/create/blur-video?step=1"
                          title="Blur video"
                        />
                        <Feature
                          href="https://crayo.ai/tools/youtube-downloader"
                          title="Youtube downloader"
                        />
                        <Feature
                          href="https://crayo.ai/tools/tiktok-downloader"
                          title="Tiktok downloader"
                        />
                      </div>
                    </li>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuTrigger>Use Cases</NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                    {components.map((component) => (
                      <Feature key={component.title} title={component.title} href={component.href}>
                        {component.description}
                      </Feature>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuTrigger>Resources</NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    <Feature href="https://crayo.tolt.io/login" title="Affiliate program">
                      Earn 20% on all paid referrals to Crayo.
                    </Feature>
                    <Feature href="https://crayo.ai/blog" title="Blog">
                      Find news, insights, and best practices.
                    </Feature>
                    <Feature href="https://crayo.ai/discord" title="Community Discord">
                      Join for customer support and feedback.
                    </Feature>
                    <Feature href="https://crayo.ai/guide" title="Guide">
                      Learn how to maximize the use of Crayo.
                    </Feature>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/pricing" legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    Pricing
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>

          <div className="flex w-1/4 items-center justify-end">
            <Button
              size="default"
              href="https://crayo.ai/dashboard"
              className="space-x-2 px-5 py-3"
            >
              <Image
                src="https://crayo-lp.vercel.app/lightningWhite.png"
                alt="Lightning"
                width={14}
                height={14}
              />
              <span className="font-ambit-bold text-[16px] text-white">Get started</span>
            </Button>
          </div>
        </>
      )}
    </header>
  );
};

const Feature = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & { imageSrc?: string }
>(({ className, title, children, href = "#", imageSrc, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          href={href as string}
          prefetch={false}
          onClick={
            href === "https://crayo.ai/blog"
              ? (e) => {
                  e.preventDefault();
                  window.open(href, "_blank");
                }
              : undefined
          }
          className={cn(
            "group block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className,
          )}
          {...props}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="space-y-1">
              <div className="flex flex-row items-center gap-1 text-sm font-medium leading-none">
                {title}
                <Image
                  src="https://crayo-lp.vercel.app/rightArrow.png"
                  alt="arrow"
                  width={10}
                  height={10}
                  className="-translate-x-4 transform opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
                />
              </div>
              <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
            </div>
            {imageSrc && <Image src={imageSrc} alt={imageSrc} width={52} height={52} />}
          </div>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
Feature.displayName = "Feature";

const Tool = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<"a">>(
  ({ className, title, children, href = "#", ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            href={href as string}
            className={cn(
              "group block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className,
            )}
            {...props}
          >
            <div className="flex flex-row items-center gap-1 text-sm font-medium leading-none">
              {title}
              <Image
                src="https://crayo-lp.vercel.app/rightArrow.png"
                alt="arrow"
                width={10}
                height={10}
                className="-translate-x-4 transform opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
              />
            </div>
            <p className="text-md line-clamp-2 text-sm leading-snug text-muted-foreground md:text-lg">
              {children}
            </p>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  },
);
Tool.displayName = "Tool";

export default Header;
